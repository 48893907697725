import type { AuthenticationDispatchers } from '@b2x/authentication-library';
import { CONNECTIONS, LOG_LEVEL } from '@b2x/core/bundle';
import { getDispatchers } from './utilities/common';
import { LOG_GROUPS, logger } from './utilities/logger';
import { buildTime, version } from './utilities/version';

async function bootstrap() {
  logger(`One Header Integrator ${version} - ${buildTime}`, {
    args: [],
    group: LOG_GROUPS.BOOTSTRAP,
    level: LOG_LEVEL.INFO,
  });

  try {
    await initializeAlertListener();
  } catch (error) {
    logger('Error while integrating OneHeader with User Menu', {
      args: [error],
      group: LOG_GROUPS.BOOTSTRAP,
      level: LOG_LEVEL.WARN,
    });
  }
}

async function initializeAlertListener() {
  const { alert } = await getDispatchers<AuthenticationDispatchers>(CONNECTIONS.AUTH_CONNECTION_NAME);

  alert(async (event: any) => {
    logger('An alert was found. ', {
      args: [],
      group: LOG_GROUPS.ALERT_USER_MENU,
      level: LOG_LEVEL.INFO,
    });

    // @ts-ignore
    const { renderNotification } = await import(/* @vite-ignore */ '@oneweb/notification');
    renderNotification({
      message: event.data.message,
      theme: 'light',
      variant: 'basic',
    });
  });
}

// https://jira.mercedes-benz.io/browse/B2XCORE-3912
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    bootstrap();
  }
});

bootstrap();
